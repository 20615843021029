// https://cydstumpel.nl/

import * as THREE from 'three'
import { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Image, Environment, ScrollControls, useScroll, useTexture } from '@react-three/drei'
import { easing } from 'maath'
import './util'
import SoundCloudEmbed from './SoundCloudEmbed'
import Banner from './Banner'

export const Home = () => (
  <div className="absolute top-0 left-0 w-screen h-screen">
    <Canvas camera={{ position: [0, 0, 100], fov: 15 }} >
      <fog attach="fog" args={['#a79', 8.5, 12]} />
      <ScrollControls pages={4} infinite>
        <Rig rotation={[0, 0, 0.15]}>
          <Carousel />
        </Rig>
        <Banner position={[0, -0.15, 0]} />
      </ScrollControls>
      <Environment preset="night" background blur={0.05} />
    </Canvas>
    <div className="fixed bottom-5 left-5 z-50 invisible md:visible">
    <SoundCloudEmbed/>
    </div>
  </div>
)

function Rig(props) {
  const ref = useRef()
  const scroll = useScroll()
  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2) // Rotate contents
    state.events.update() // Raycasts every frame rather than on pointer-move
    easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta) // Move camera
    state.camera.lookAt(0, 0, 0) // Look at center
  })
  return <group ref={ref} {...props} />
}

function Carousel({ radius = 1.4, count = 8 }) {
  return Array.from({ length: count }, (_, i) => (
    <Card
      key={i}
      url={`/${Math.floor(i % 11) + 1}.png`}
      position={[Math.sin((i / count) * Math.PI * 2) * radius, 0, Math.cos((i / count) * Math.PI * 2) * radius]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
    />
  ))
}

function Card({ url, ...props }) {
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const pointerOver = (e) => (e.stopPropagation(), hover(true))
  const pointerOut = () => hover(false)
  // useFrame((state, delta) => {
  //   easing.damp3(ref.current.scale, hovered ? 1.15 : 1, 0.1, delta)
  //   easing.damp(ref.current.material, 'radius', hovered ? 0.25 : 0.1, 0.2, delta)
  //   easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta)
  // })
  return (
    <Image ref={ref} url={url} transparent side={THREE.DoubleSide} onPointerOver={pointerOver} onPointerOut={pointerOut} {...props}>
      <bentPlaneGeometry args={[0.1, 1, 1, 20, 20]} />
    </Image>
  )
}

// function Banner(props) {
//   const ref = useRef();
//   const videoRef = useRef();

//   useEffect(() => {
//     const video = document.createElement('video');
//     video.src = '/video.mp4';  
//     video.crossOrigin = 'Anonymous';
//     video.loop = true;
//     video.muted = true;
//     video.play();
//     videoRef.current = video;

//     const videoTexture = new THREE.VideoTexture(video);
//     videoTexture.wrapS = videoTexture.wrapT = THREE.RepeatWrapping;
//     ref.current.material.map = videoTexture;
//   }, []);

//   const scroll = useScroll();

//   useFrame((state, delta) => {
//     if (ref.current) {
//       ref.current.material.map.offset.x += delta / 10;
//     }
//   });

//   return (
//     <mesh ref={ref} {...props}>
//       <cylinderGeometry args={[1.6, 1.6, 0.14, 128, 16, true]} />
//       <meshBasicMaterial map={null} side={THREE.DoubleSide} toneMapped={false} />
//     </mesh>
//   );
// }

export default Home
