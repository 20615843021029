import { createRoot } from 'react-dom/client'
import './index.css'
import { Logo } from '@pmndrs/branding'
import { App } from './Home'
import ReactPlayer from 'react-player'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Archive from './Archive'
import Home from './Home'

function Root() {
  return (
    <>
      {/* <div
        style={{
          backgroundImage: "url('/bg.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      ></div> */}
      <div className="fixed bottom-5 right-5 z-50">
        <img width={'100px'} src="/work_.png"></img>
      </div>
      <div>
        <Router>
          <div>
            <div className="fixed top-5 font-semibold z-50 flex flex-row justify-center items-center w-screen gap-10">
              <div className="relative">
                <Link className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none" to="/">
                  home
                </Link>
              </div>
              <div className="relative">
                <Link
                  className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none"
                  to="https://www.4dimensionapparel.com/categories/nul1org"
                  target="_blank"
                  rel="noopener noreferrer">
                  shop
                </Link>
              </div>
              <div className="relative">
                <Link className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none" to="/archive">
                  archive
                </Link>
              </div>
              <div className="relative">
                <Link
                  className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none"
                  to="https://www.instagram.com/nul1org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  instagram
                </Link>
              </div>
            </div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/archive" element={<Archive />} />
            </Routes>
          </div>
        </Router>
      </div>
    </>
  )
}

createRoot(document.getElementById('root')).render(<Root />)