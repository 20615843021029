const SoundCloudEmbed = () => (
    <div>
      <iframe
        width="400"
        height="80"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1801437330&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true">
      </iframe>
      {/* <div style={{
        fontSize: '10px',
        color: '#cccccc',
        lineBreak: 'anywhere',
        wordBreak: 'normal',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif',
        fontWeight: '100'
      }}>
        <a 
          href="https://soundcloud.com/projectskylateofficial" 
          title="Project Skylate" 
          target="_blank" 
          style={{color: '#cccccc', textDecoration: 'none'}}
        >
          Project Skylate
        </a> 
        &middot; 
        <a
          href="https://soundcloud.com/projectskylateofficial/shelhiel-budebuai-project-skylate-remix"
          title="Shelhiel - BuDeBuAi 不得不愛(Project Skylate Remix)"
          target="_blank"
          style={{color: '#cccccc', textDecoration: 'none'}}
        >
          Shelhiel - BuDeBuAi 不得不愛(Project Skylate Remix)
        </a>
      </div> */}
    </div>
  );
  
  export default SoundCloudEmbed;